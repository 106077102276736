import * as React from 'react';
import { HeadProps } from 'gatsby';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { ImageSocialHome } from '../assets/images/social';
import { Subtitle, Text, Title } from '../styles/typography';
import { Container } from '../styles/layout';

interface Props {
	location: Location;
}

const CookiesContainer = styled(Container)`
	a {
		color: rgb(54, 170, 72);
		text-decoration: underline;
	}
`;

const SmallerTitle = styled(Title)`
	font-size: 40px;
	line-height: 60px;
`;

const SmallerSubtitle = styled(Subtitle)`
	font-size: 24px;
	line-height: 30px;
	margin-top: 40px;
`;

const TextWithPadding = styled(Text)`
	text-align: left;
	margin-top: 20px;
`;

const TextList = styled.ul`
	padding-left: 20px;
	margin-top: 10px;
	font-size: 18px;
	line-height: 27px;

	${down('md')} {
		font-size: 16px;
		line-height: 24px;
	}
`;

const TextTableContainer = styled.div`
	margin-top: 20px;
	overflow-x: auto;

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	th,
	td {
		padding: 5px;
		border: 1px solid #242424;
	}

	th {
		text-align: left;
	}
`;

const CookiesPage = ({ location }: Props) => {
	return (
		<Layout location={location}>
			<CookiesContainer>
				<SmallerTitle>O piškotkih</SmallerTitle>
				<TextWithPadding>
					Piškotki so kratke besedilne datoteke, ki jih spletno mesto pošlje brskalniku (če jih spletno mesto
					le uporablja). Glavni cilj piškotkov je, da spletni strežnik prepozna računalnik. Piškotki niso
					nujni vendar lahko bistveno izboljšajo uporabniško izkušnjo. Na straneh uporabljamo piškotke
					izključno za analizo obiskanosti spletenga mesta. Upoštevajte, da se z uporabo spletne strani
					strinjate, da lahko uporabljamo piškotke.
				</TextWithPadding>
				<SmallerSubtitle>Vrste piškotkov</SmallerSubtitle>
				<TextWithPadding>
					Piškotki so, recimo jim vrsta datoteke, ki jih med vašim obiskom spletne strani le-ta ustvari in
					vanje shrani podatke. Tako vam je ob naslednjem obisku zagotovljeno mnogo lažje in hitrejše brskanje
					po strani. Ločimo dve vrsti piškotkov, in sicer:
				</TextWithPadding>
				<TextList>
					<li>
						Začasni piškotki, ki so ustvarjeni zgolj začasno in beležijo podatke med vašim brskanjem. Ko
						zaprete okno vašega brskalnika, se piškotki izbrišejo.
					</li>
					<li>
						Trajni piškotki ostanejo na vašem računalniku ali drugi napravi in so aktivirani vsakokrat, ko
						obiščete spletno stran.
					</li>
				</TextList>
				<SmallerSubtitle>Katere trajne piškotke uporablja naša stran?</SmallerSubtitle>
				<TextWithPadding>
					V spodnji tabeli je navedeno, katere piškotke uporablja naša spletna stran in kakšen je njihov
					namen.
				</TextWithPadding>
				<TextTableContainer>
					<table>
						<tbody>
							<tr>
								<th>Ime</th>
								<th>Vir</th>
								<th>Trajanje</th>
								<th>Namen</th>
								<th>Domena</th>
							</tr>
							<tr>
								<td>_ga</td>
								<td>Google Analytics</td>
								<td>2 leti od namestitve</td>
								<td>
									Uporablja se za razlikovanje uporabnikov.{' '}
									<a
										href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#overview"
										target="_blank"
									>
										Več informacij
									</a>
								</td>
								<td>.tpp.si</td>
							</tr>
							<tr>
								<td>{'_ga_<container-id>'}</td>
								<td>Google Analytics</td>
								<td>2 leti od namestitve</td>
								<td>
									Uporablja se za ohranitev stanja seje.{' '}
									<a
										href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#overview"
										target="_blank"
									>
										Več informacij
									</a>
								</td>
								<td>.tpp.si</td>
							</tr>
							<tr>
								<td>gdpr-gtag</td>
								<td>Privolitev v uporabo piškotkov</td>
								<td>1 leto od namestitve</td>
								<td>
									Uporablja se za ohranitev informacije, če dovolite shranjevanje piškotkov za našo
									spletno stran.
								</td>
								<td>.tpp.si</td>
							</tr>
						</tbody>
					</table>
				</TextTableContainer>
				<SmallerSubtitle>Onemogočenje piškotkov</SmallerSubtitle>
				<TextWithPadding>
					V nastavitvah brskalnika lahko piškotke kadarkoli izklopite. Informacije o nastavitvah za piškotke v
					posameznih brskalnikih so na voljo na spodnjih povezavah:
				</TextWithPadding>
				<TextList>
					<li>
						<a
							href="https://support.google.com/chrome/answer/95647?hl=sl-sl&amp;p=cpn_cookies"
							target="_blank"
						>
							Chrome
						</a>
					</li>
					<li>
						<a href="https://support.mozilla.org/sl/kb/piskotki" target="_blank">
							Firefox
						</a>
					</li>
					<li>
						<a
							href="http://windows.microsoft.com/sl-si/windows-vista/block-or-allow-cookies"
							target="_blank"
						>
							Internet Explorer
						</a>
					</li>
					<li>
						<a href="http://www.opera.com/help/tutorials/security/privacy/" target="_blank">
							Opera
						</a>
					</li>
					<li>
						<a href="http://www.apple.com/support/safari/" target="_blank">
							Safari
						</a>
					</li>
				</TextList>
			</CookiesContainer>
		</Layout>
	);
};

export const Head = (props: HeadProps) => {
	return (
		<Seo
			title={'O piškotkih'}
			description={''}
			image={ImageSocialHome}
			locationPathName={props.location.pathname}
		/>
	);
};

export default CookiesPage;
